@import '../styles/variables.css';

.filter-buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  gap: 6px;
}

.filter-button {
  padding: 10px 18px;
  border: none;
  border-radius: 20px;
  background-color: var(--button-bg-color);
  cursor: pointer;
  font-size: 16px;
  margin: 5px;
  white-space: nowrap;
}

.filter-button.selected {
  background-color: var(--button-selected-color);
  color: var(--text-color-light);
}

.filter-button:hover {
  background-color: var(--button-hover-color);
  color: var(--text-color-light);
}

@media (max-width: 600px) {
  .filter-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}

/* https://colorffy.com/dark-theme-generator */
:root {
  --color-primary-100: #382bf0;
  --color-primary-200: #5e43f3;
  --color-surface-100: #121212;
  --color-surface-200: #282828;
  --color-surface-300: #3f3f3f;

  /* Text Colors */
  --text-color-dark: #333;
  --text-color-light: #f4f4f4;
  --hyperlink-color: #007bff;

  /* Background Colors */
  --bg-color-light: #f4f4f4;
  --bg-color-dark: #333;

  /* Button Colors */
  --button-bg-color: #f0f0f0;
  --button-hover-color: #0056b3;
  --button-selected-color: #007bff;

  /* Additional Colors */
  --danger-color: #FF6347;
  --highlight-color: #e22000;
  --border-color: #ddd;
}

html, body, #root, .App {
  height: 100%;
  margin: 0;
  padding: 0;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

main {
  flex: 1;
  padding: 20px;
}

.tooltip {
  position: absolute;
  top: -25px;
  left: 0;
  background-color: var(--color-surface-100);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  opacity: 1;
  transition: opacity 0.3s;
  pointer-events: none;
  white-space: nowrap;
  z-index: 9999;
}

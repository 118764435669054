.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.modal-content textarea {
  width: 100%;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  resize: vertical;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.modal-actions button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-actions button[type="button"] {
  background: #ccc;
}

.modal-actions button[type="submit"] {
  background: var(--button-selected-color);
  color: var(--text-color-light);
}

.modal-content.light {
  background: white;
  border: 1px solid var(--border-color);
  color: var(--text-color-dark);
}

/* Dark Theme */
.modal-content.dark {
  background: var(--color-surface-200);
  border: 1px solid var(--color-surface-200);
  color: var(--text-color-light);
}

@import '../styles/variables.css';

.no-results {
  text-align: center;
  margin-top: 20px;
}

.no-results.light {
  color: var(--text-color-dark);
}

.no-results.dark {
  color: var(--text-color-light);
}

.no-results p {
  font-size: 18px;
}

.no-results a {
  color: var(--hyperlink-color);
  text-decoration: none;
}

.no-results a:hover {
  text-decoration: underline;
}

.no-results img {
  max-width: 50%;
  height: auto;
  margin-top: 20px;
}

.no-results-image.dark {
    -webkit-filter: invert(1);
    filter: invert(1);
}

.report-link {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  font: inherit;
}

.report-link:hover {
  color: #0056b3;
  text-decoration: underline;
}

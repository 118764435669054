.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 14px 0;
  position: relative;
}

.search-bar input {
  padding: 10px;
  font-size: 16px;
  width: 80%;
  max-width: 500px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
}

.clear-button {
  background: none;
  border: none;
  cursor: pointer;
}

.clear-button img {
  width: 40px;
  height: 40px;
}

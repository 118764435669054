.tool-card {
  border-radius: 5px;
  padding: 10px;
  width: 280px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  text-align: left;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.pin-icon-wrapper {
  position: absolute;
  top: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.tool-heading {
  font-weight: bold;
}

.tool-card img.pin-icon {
  width: 24px;
  height: 24px;
}

.tool-card img.pin-icon.dark {
  -webkit-filter: invert(1);
  filter: invert(1);
}

.tool-content {
  display: flex;
  align-items: center;
}

.image-container {
  position: relative;
}

.tool-card img.tool-image {
  max-width: 60px;
  max-height: 60px;
  margin-right: 10px;
  object-fit: contain;
}

.tool-card img.official-icon {
  position: absolute;
  top: -4px;
  left: -4px;
  width: 20px;
  height: 20px;
}

.tool-details {
  display: flex;
  flex-direction: column;
  align-self: flex-start;
}

.tool-card h2 {
  margin: 0 0 5px 0;
}

.tool-card a {
  display: inline-block;
  padding: 10px 15px;
  background-color: var(--button-selected-color);
  color: var(--text-color-light);
  text-decoration: none;
  border-radius: 5px;
  text-align: center;
  width: 100%;
}

.tool-card a:hover {
  background-color: var(--button-hover-color);
}

.tool-card a img.external-link-icon {
  margin-left: 5px;
  width: 18px;
  height: 18px;
  vertical-align: middle;
}

.tool-card p {
  margin: 10px 0;
}

.categories {
  font-style: italic;
  font-size: 13px;
}

.keywords {
  font-size: 13px;
  margin: 0;
  color: #007bff;
}

.keywords .keyword {
  cursor: pointer;
  color: #007bff;
  margin-right: 5px;
}

.keywords .keyword:hover {
  text-decoration: underline;
}

.tool-card-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0 0 0;
}

.tool-card a.report-button {
  cursor: pointer;
  background-color: var(--danger-color);
  margin: 0 0 0 10px ;
  padding: 10px 15px;
  text-decoration: none;
  border-radius: 5px;
  width: 33%;
}

.tool-card a.report-button:hover {
  background-color: var(--highlight-color);
}

.report-button img {
  width: 18px;
  height: 18px;
}

.not-working {
  position: absolute;
  max-width: 130px;
  top: 0;
  right: 0;
}

/* Light Theme */
.tool-card.light {
  background: white;
  border: 1px solid var(--border-color);
  color: var(--text-color-dark);
}

/* Dark Theme */
.tool-card.dark {
  background: var(--color-surface-200);
  border: 1px solid var(--color-surface-200);
  color: var(--text-color-light);
}

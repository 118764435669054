.no-pinned-tools {
  text-align: center;
  padding: 20px;
  border-radius: 5px;
}

.no-pinned-tools h1 {
  margin-bottom: 10px;
}

.no-pinned-tools p {
  margin-bottom: 20px;
}

.no-pinned-tools img.pin-example {
  max-width: 100%;
  height: auto;
  border: 1px solid var(--border-color);
}

/* Light Theme */
.no-pinned-tools.light {
  background: white;
  border: 1px solid var(--border-color);
  color: var(--text-color-dark);
}

/* Dark Theme */
.no-pinned-tools.dark {
  background: var(--color-surface-200);
  border: 1px solid var(--color-surface-200);
  color: var(--text-color-light);
}

footer {
  margin-top: 10px;
  text-align: center;
  padding: 10px 0;
  width: 100%;
}

footer.dark {
  background-color: var(--color-surface-100);
  color: var(--text-color-light);
}

footer.light {
  background-color: var(--color-primary-200);
  color: var(--text-color-light);
}

.theme-toggle-container {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
}

.theme-toggle-checkbox {
  opacity: 0;
  width: 0;
  height: 0;
}

.theme-toggle-label {
  display: block;
  width: 60px;
  height: 34px;
  background-color: #ccc;
  border-radius: 34px;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s;
}

.theme-toggle-checkbox:checked + .theme-toggle-label {
  background-color: #4e4e4e;
}

.theme-toggle-ball {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: white;
  border-radius: 50%;
  top: 4px;
  left: 4px;
  transition: transform 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.theme-toggle-checkbox:checked + .theme-toggle-label .theme-toggle-ball {
  transform: translateX(26px);
}

.theme-toggle-icon {
  width: 20px;
  height: 20px;
}

header {
  text-align: center;
  padding: 10px;
}

.nav-links {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.nav-links li {
  display: inline;
}

.nav-links a {
  text-decoration: none;
  color: inherit;
  font-weight: bold;
  position: relative;
}

.nav-links a:hover {
  text-decoration: underline;
}

.bubble-count {
  background: var(--color-primary-100);
  color: white;
  border-radius: 10px;
  padding: 2px 4px;
  font-size: 11px;
}

/* Light Theme */
header.light {
  background: white;
  color: var(--text-color-dark);
}

/* Dark Theme */
header.dark {
  background: var(--color-surface-200);
  color: var(--text-color-light);
}
